import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./assets/css/common.scss";
import App from "./App";
import router from "./router";
/* 引入aos动画库相关文件 */
import AOS from "aos";
import "aos/dist/aos.css";
/* aos动画初始化 */
AOS.init({
  duration: 1000,
  easing: "ease-in-out-back",
});
createApp(App)
  .use(router)
  .use(ElementPlus)
  .mount(document.getElementById("app"));
