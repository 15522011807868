import { createRouter, createWebHistory } from "vue-router";

const Home = () => import("../views/home");
const Product = () => import("../views/product");
const About = () => import("../views/about");
const Join = () => import("../views/join");
const Contact = () => import("../views/contact");
export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/home",
      component: Home,
    },
    {
      path: "/product",
      component: Product,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/join",
      component: Join,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home", // 404 页面，重定向到主页
    },
  ],
});
