<template>
  <Header></Header>
  <main>
    <RouterView></RouterView>
  </main>
  <Footer></Footer>
  <el-backtop>
  </el-backtop>
</template>

<script setup>
import Header from './views/header'
import Footer from './views/footer'
</script>

<style></style>
