<template>
    <footer>
        Copyright&nbsp;©&nbsp;2023&nbsp;www.hangfukeji.com
        <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow">豫ICP备2023028885号-1</a>
    </footer>
</template>
<style scoped>
footer {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2b313d;
    color: #999;

    a {
        color: #999;
        margin-left: 20px;

        &:hover {
            color: #ccc;
        }
    }
}
</style>
